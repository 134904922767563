import React from 'react';
import Layout from '../containers/Layout';
import ServiceAreas from '../containers/ServiceAreas';

const ServiceAreasPage: React.FC = () => (
  <Layout title="Service Areas | Solar Installation Company | Solvana">
    <ServiceAreas />
  </Layout>
);

export default ServiceAreasPage;
