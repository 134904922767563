import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Hero: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "service-areas-solar-installation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
            Solar Energy
            <br /> Service Areas
          </Typography>

          <Typography variant="body1">
            Solvana installs photovoltaic solar and battery systems throughout several U.S. states, saving homeowners
            money on their electric bills through clean solar energy.
          </Typography>
          <Typography variant="body1">
            We are a leading solar energy company with more than 50 years of combined experience in the solar and
            construction industries. We made buying solar and batteries super easy, and we are happy to manage the
            entire installation process for you. We offer solar system design and installation services for homeowners
            interested in saving money on their electric bills through solar energy. We&#39;re growing rapidly and
            service the following areas:
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default Hero;
