import React from 'react';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';

const Content: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <div className={classes.copyContainer}>
        <Typography variant="h2">Solvana Solar Installation Service Areas</Typography>
        <Typography component="div" variant="body1">
          <ul>
            <li>
              <Link to={Routes.NjSolarEnergy}>New Jersey Solar Energy</Link>
            </li>
            <li>
              <Link to={Routes.SolarPanelInstallationFlorida}>Solar Panel Installation Florida</Link>
            </li>
            <li>
              <Link to={Routes.TexasSolarPanelInstallation}>Texas Solar Panel Installation</Link>
            </li>
            <li>
              If you don&#39;t see your state listed,&nbsp;
              <Link to={Routes.ContactUs}>contact us</Link>.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1">
          If you live in one of our service areas and are interested in learning more about solar energy, you can get
          a&nbsp;
          <Link to={Routes.SmartFastEasy}>free solar installation proposal here</Link>. We&#39;ll be happy to answer any
          of your questions and provide you with a free estimate.
        </Typography>

        <Typography variant="body1">
          If you don&#39;t see your state listed,&nbsp;
          <Link to={Routes.ContactUs}>contact us</Link>&nbsp;to inquire about service in your area. Our solar installers
          are the best in the business and will work with you to develop a custom solution for your home that can help
          you save money. We offer free consultations and will work with you to find the best solar solution for your
          energy needs.
        </Typography>

        <br />
        <Typography variant="h2">Save Money On Your Energy Costs With Solvana Solar</Typography>

        <Typography variant="body1">
          Are you tired of watching your electric bill go up and up? Are you looking for a way to save money on your
          energy costs? If so, solar panels may be the answer for you! Installing solar panels can save you hundreds of
          dollars each year on your electric bill. Solar panels can help you save money in a variety of ways. For
          example, solar panels can:
        </Typography>

        <List>
          <ListItem>
            <Typography variant="h4">Reduce or eliminate your electric bill</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h4">Save you money on your tax bill through government credits</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h4">Increase the value of your home</Typography>
          </ListItem>
        </List>

        <br />
        <Typography variant="h2">Free Digital Solar Energy Proposal</Typography>

        <Typography variant="body1">
          Our 100% online buying process is the easiest in the industry&mdash; we&#39;ve eliminated the hassles and
          commissions associated with salespeople. When you&nbsp;
          <Link to={Routes.SmartFastEasy}>request your free digital solar proposal</Link>, our team will provide a
          custom solar installation quote within 48 hours.
        </Typography>

        <Typography variant="body1">
          We guide you through all the steps involved in your Solvana solar installation from start to finish. Our
          secure website empowers you to review, select, and buy the right solar equipment designed for your energy
          requirements. We&rsquo;re with you every step of the way, from solar panel system design to permitting and
          installation. We guarantee you&#39;ll enjoy lower electric bills through a high-quality solar installation at
          competitive prices without sacrificing quality or service.
        </Typography>

        <Typography variant="body1">
          If you&#39;re interested in going solar, contact us today and we&#39;ll help you determine whether solar is
          right for your home.
        </Typography>

        <Typography variant="body1">
          Call us toll-free at &nbsp;<a href="tel:18882052462">1-888-205-2462</a>&nbsp; or &nbsp;
          <Link to={Routes.SmartFastEasy}>click here to get a free solar proposal</Link>. (or reach out via email &nbsp;
          <a href="mailto:Info@solvana.io">Info@solvana.io</a>)
        </Typography>
      </div>
    </Container>
  );
};

export default Content;
